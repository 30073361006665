/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'

import CartProvider from '../../Providers/CartProvider'

import Layout from '../../Layout'
import SEO from '../../SEO'

import ImagesGallery from './ImagesGallery'
import VariantSelect from './VariantSelect'
import Description from './Description'
import AddButton from '../../components/AddButton'

import {
  Container,
  Title,
  Price,
  NotAvailable,
  OptionsContent,
} from './styles'

import { useGetVariantId, useVariantNavigation, useGetImageId } from './helpers'

export const query = graphql`
  query ProductQuery($shopifyId: String) {
    shopifyProduct(shopifyId: {eq: $shopifyId}) {
      ...ProductPage
    }
  }
`

const ProductTemplate = props => {
  const { data: { shopifyProduct: product } } = props
  const [selectedVariantId, setCurrentVariantId] = useGetVariantId(product)
  const [imageId, setImageId] = useGetImageId(product, selectedVariantId)

  useVariantNavigation(selectedVariantId)

  const [fetching, setFetching] = useState(false)
  const [
    availableVariantsShopifyIds,
    setAvailableVariantsShopifyIds,
  ] = useState([])

  const { getProductByShopifyId } = useContext(CartProvider)
  useEffect(() => {
    const getAsyncProduct = async shopifyId => {
      setFetching(true)
      const product = await getProductByShopifyId(shopifyId)

      // eslint-disable-next-line no-negated-condition
      if (!product.availableForSale) {
        setAvailableVariantsShopifyIds([])
      } else {
        const availableVariantIds = product.variants.reduce((acc, v) => {
          if (v.available) return [...acc, v.id]
          return acc
        }, [])

        setAvailableVariantsShopifyIds(availableVariantIds)
      }

      setFetching(false)
    }

    getAsyncProduct(product.shopifyId)
  }, [
    setFetching,
    setAvailableVariantsShopifyIds,
    getProductByShopifyId,
    product.shopifyId])

  const currentVariant = product.variants.find(v => v.id === selectedVariantId)
  const variantIsAvailable = availableVariantsShopifyIds
    .includes(currentVariant.shopifyId)

  const availableMessage = fetching ? ''
    : availableVariantsShopifyIds.length === 0
      ? 'C\'est produit n\'est pas disponible'
      : variantIsAvailable
        ? ''
        : `La version ${currentVariant.title || ''} n'est pas disponible`

  return (
    <Layout>
      <SEO
        title={product.title}
        description={product.description}
        keywords={product.tags}
        image={product?.images?.[0]?.localFile?.childImageSharp?.resize}
      />
      <Container>
        <Title>{product.title}</Title>
        <ImagesGallery
          selectedImageId={imageId}
          images={product?.images || []}
          onChange={id => { setImageId(id) }}
        />
        <OptionsContent>
          <div>
            <VariantSelect
              value={selectedVariantId}
              onChange={id => { setCurrentVariantId(id) }}
              variants={product.variants}
            />
            <Price>
              {currentVariant.price}
&nbsp;€
            </Price>
            <NotAvailable>
              {availableMessage}
            </NotAvailable>
            <AddButton
              disabled={fetching || !variantIsAvailable}
              variantShopifyId={currentVariant.shopifyId}
            />
          </div>
          <Description text={product.descriptionHtml} />
        </OptionsContent>
      </Container>
    </Layout>
  )
}

ProductTemplate.defaultProps = {
  data: null,
}

ProductTemplate.propTypes = {
  data: shape({}),
}

export default ProductTemplate
