import { useState, useEffect } from 'react'
import { parse } from 'query-string'
import { navigate, useLocation } from '@reach/router'

const getCorretVariant = (product, urlDecodedVariantId) => {
  if (urlDecodedVariantId) {
    const variant = product?.variants?.find(v => v.id === urlDecodedVariantId)
    if (variant) return variant.id
  }
  return product?.variants?.[0].id
}

export const useGetVariantId = product => {
  const { search } = useLocation()
  const { v } = parse(search)

  const urlDecodedVariantId = v ? decodeURIComponent(v) : null
  const initialVariantId = getCorretVariant(product, urlDecodedVariantId)

  const [currentVariantId, setCurrentVariantId] = useState(initialVariantId)

  return [currentVariantId, setCurrentVariantId]
}

export const useVariantNavigation = currentVariantId => {
  const { search, origin, pathname } = useLocation()
  const { v } = parse(search)
  const urlVariantId = v ? decodeURIComponent(v) : null

  useEffect(() => {
    if (!urlVariantId || urlVariantId !== currentVariantId) {
      // eslint-disable-next-line max-len
      navigate(`${origin}${pathname}?v=${encodeURIComponent(currentVariantId)}`, { replace: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariantId, urlVariantId])
}

export const useGetImageId = (product, variantId) => {
  const variant = product.variants.find(v => v.id === variantId)
  const initialImgId = variant?.image?.id || product.images?.[0]?.id || null

  const [imageId, setImageId] = useState(initialImgId)

  useEffect(() => {
    const variant = product.variants.find(v => v.id === variantId)

    if (variant && variant.image && variant.image.id !== imageId) {
      setImageId(variant.image.id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantId])

  return [imageId, setImageId]
}
