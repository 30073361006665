import React from 'react'
import {
  arrayOf, shape, string, func,
} from 'prop-types'

import Image from 'gatsby-image'

import { ThumbnailsContainer, Thumbnail } from './styles'

const Thumbails = ({ currentImgId, images, onChange }) => {
  if (!images || images.length <= 1) return null

  return (
    <ThumbnailsContainer>
      {
        images.map(img => (
          <Thumbnail
            key={img.id}
            isSelected={currentImgId === img.id}
            onClick={() => { onChange(img.id) }}
          >
            <Image
              style={{ width: '100%', height: '100%' }}
              fluid={img.localFile.childImageSharp.fluid}
            />
          </Thumbnail>
        ))
      }
    </ThumbnailsContainer>
  )
}

Thumbails.propTypes = {
  currentImgId: string.isRequired,
  images: arrayOf(shape({})).isRequired,
  onChange: func.isRequired,
}

export default Thumbails
