import React from 'react'
import {
  arrayOf, shape, string, func,
} from 'prop-types'
import Image from 'gatsby-image'

import Thumbails from './Thumbails'

import { ImgContainer } from './styles'

import { thereIsImage } from './helpers'

const ImagesGallery = ({ selectedImageId, images, onChange }) => {
  if (!thereIsImage(images)) {
    return <div className="imagesGallery" />
  }

  const image = images.find(img => img.id === selectedImageId) || images[0]

  return (
    <ImgContainer className="imagesGallery">
      <Image
        style={{ maxHeight: 600 }}
        fluid={image.localFile.childImageSharp.fluid}
      />
      <Thumbails currentImgId={image.id} images={images} onChange={onChange} />
    </ImgContainer>
  )
}

ImagesGallery.defaultProps = {
  images: null,
  selectedImageId: null,
}

ImagesGallery.propTypes = {
  selectedImageId: string,
  images: arrayOf(shape({})),
  onChange: func.isRequired,
}

export default ImagesGallery
