import React from 'react'
import { bool, node, string } from 'prop-types'
import styled from 'styled-components'

import Spinner from '../Spinner'

const Btn = styled.button`
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  padding: 6px 16px;
  min-height: 40px;
  min-width: 64px;
  width: 100%;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  font-family: 'Lato', sans-serif;
  background-color: ${({ $loading, theme: { color } }) => $loading
    ? color.secondary.light
    : 'transparent'};
  border: 1px solid ${({ theme: { color } }) => color.secondary.main};
  color: ${({ theme: { color } }) => color.secondary.main};
  transition: 250ms ${({ theme: { animation } }) => animation.func_animation};

  &:hover, &:focus, &:active {
    background-color: ${({ theme: { color } }) => color.secondary.light};
    color: ${({ theme: { color } }) => color.primary.main};
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.3); 
    border: 1px solid rgba(255, 255, 255, 0.3); 
  }

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.mobile}) {
    width: auto;
    min-width: 190px;
  }
`

const Button = ({
  children, loading, spinnerColor, ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Btn $loading={loading} {...props}>
    {loading ? <Spinner color={spinnerColor} /> : children}
  </Btn>
)

Button.defaultProps = {
  loading: false,
  spinnerColor: null,
}

Button.propTypes = {
  children: node.isRequired,
  loading: bool,
  spinnerColor: string,
}

export default Button
