import React from 'react'
import {
  arrayOf, shape, string, func,
} from 'prop-types'

import Select from '../../components/Select'

const VariantSelect = ({ value, onChange, variants }) => {
  if (!variants || variants.length === 1) {
    return null
  }

  const handleOnChange = e => {
    onChange(e.target.value)
  }

  return (
    <Select value={value} onChange={handleOnChange} options={variants} />
  )
}

VariantSelect.defaultProps = {
  variants: null,
}

VariantSelect.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  variants: arrayOf(shape({})),
}

export default VariantSelect
