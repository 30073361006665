import React from 'react'
import { string } from 'prop-types'

import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

const Svg = styled.svg`
  animation: ${rotate} 2s linear infinite;
  z-index: 2;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  .path {
    stroke: ${({ color, theme }) => color || theme.color.text_negative};
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`

const Spinner = ({ size, color }) => (
  <Svg size={size} color={color} viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
    />
  </Svg>
)

Spinner.defaultProps = {
  size: '25px',
  color: null,
}

Spinner.propTypes = {
  size: string,
  color: string,
}

export default Spinner
