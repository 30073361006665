import styled from 'styled-components'

export const Title = styled.div`
  text-align: left;
  color: ${({ theme: { color } }) => color.secondary.main};
  font-weight: bold;
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
`

export const OptionsContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const Price = styled.div`
  padding: 20px 5px 0px;
  font-weight: bold;
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
`

export const NotAvailable = styled.div`
  color: ${({ theme: { color } }) => color.red};
  font-size: ${({ theme: { size } }) => size.fontSize.s};
  min-height: 16px;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 30px 1fr;
    column-gap: 20px;

    ${Title} {
      //Title
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }

    .imagesGallery {
      //Image Container
      max-width: 600px;
      margin-top: 0px;
      grid-column: 1 / span 1;
      grid-row: 1 / span 2;
    }

    ${OptionsContent} {
      // Variant, Quantity, Add to Cart, Price
      flex-direction: column-reverse;
      justify-content: flex-end;

      .descriptionProduct {
        // Product Description Container
        margin-top: 10px;
      }
    }
  }
`
