import styled from 'styled-components'

export const ImgContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`

export const ThumbnailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px;
  margin-top: 2px;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.mobile}) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.laptop}) {
    grid-template-columns: repeat(6, 1fr);
  }
`

export const Thumbnail = styled.div`
  cursor: pointer;
  ${({ isSelected, theme }) => isSelected
    ? `border: 2px solid ${theme.color.secondary.main};`
    : ''}
`
