import React, { useState, useContext } from 'react'
import { bool, string } from 'prop-types'

import StyledLink from '../StyledLink'

import Button from '../Button'
import Quantity from './Quantity'

import { Container, Line, GoToCartButton } from './styles'

import CartProvider from '../../Providers/CartProvider'

import { getIsValidQuantity } from './helpers'

const AddButton = ({ disabled, variantShopifyId }) => {
  const [quantity, setQuantity] = useState('1')
  const [loading, setLoading] = useState(false)
  const { updateLineItem, checkout } = useContext(CartProvider)

  const onChangeQuantity = e => {
    const newQuantity = e.target.value
    if (newQuantity === '') {
      setQuantity(newQuantity)
    } else {
      const cleanNumber = parseInt(newQuantity, 10)
      if (cleanNumber) {
        setQuantity(`${cleanNumber}`)
      }
    }
  }

  const onSubmit = async () => {
    setLoading(true)
    const quantityInt = parseInt(quantity || '1', 10) || 1
    await updateLineItem({ variantId: variantShopifyId, quantity: quantityInt })
    setLoading(false)
    if (typeof window === 'undefined' || !window.document) {
      const btn = document.getElementById('add-to-cart-button')
      if (btn && btn.blur) {
        btn.blur()
      }
    }
  }

  const isValidQuantity = getIsValidQuantity(quantity)
  const ableToCheckout = checkout?.lineItems?.length > 0

  return (
    <Container>
      <Line>
        <Quantity quantity={quantity} onChange={onChangeQuantity} />
        <Button
          id="add-to-cart-button"
          onClick={e => {
            e.preventDefault()
            if (!loading && !disabled && isValidQuantity) {
              onSubmit()
            }
          }}
          disabled={disabled || !isValidQuantity}
          type="button"
          loading={loading}
        >
          Ajouter au Panier
        </Button>
      </Line>
      {ableToCheckout && (
        <StyledLink to="/cart">
          <Line>
            <GoToCartButton
              type="button"
            >
              Passer la commande
            </GoToCartButton>
          </Line>
        </StyledLink>
      )}
    </Container>
  )
}

AddButton.propTypes = {
  disabled: bool.isRequired,
  variantShopifyId: string.isRequired,
}

export default AddButton
