import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  padding: 5px;
  margin-top: 25px;
`

const Description = ({ text }) => (
  <Container
    className="descriptionProduct"
    dangerouslySetInnerHTML={{ __html: text }}
  />
)

Description.defaultProps = {
  text: null,
}

Description.propTypes = {
  text: string,
}

export default Description
