import React from 'react'
import { string, func } from 'prop-types'

import { QuantityContainer, QuantityLabel, QuantityInput } from './styles'

const Quantity = ({ quantity, onChange }) => (
  <QuantityContainer>
    <QuantityLabel>Qté&nbsp;:</QuantityLabel>
    <QuantityInput
      type="number"
      min="1"
      step="1"
      value={quantity}
      onChange={onChange}
    />
  </QuantityContainer>
)

Quantity.propTypes = {
  quantity: string.isRequired,
  onChange: func.isRequired,
}

export default Quantity
