import styled from 'styled-components'
import Input from '../Input'
import Button from '../Button'

export const Container = styled.div`
  margin: 25px 0px;
`

export const Line = styled.div`
  display: flex;
  margin-bottom: 15px;
`

export const QuantityContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 15px;
  border: 1px solid ${({ theme: { color } }) => color.text};
  border-radius: 4px;
`

export const QuantityLabel = styled.span`
  padding-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
`

export const QuantityInput = styled(Input)`
  width: 80px;
  height: 100%;
  border-radius: 0px;
  border: none;
  background-color: transparent;
  color: ${({ theme: { color } }) => color.text};

  &:focus {
    border: none;
  }
`

export const GoToCartButton = styled(Button)`
  width: 100%;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.mobile}) {
    max-width: 330px;
  }
`
